import { lazy } from "react";

// project imports
import Loadable from "../components/Loader/Loadable";
import AuthLayout from "../layouts/AuthLayout/AuthLayout";

const Dashboard = Loadable(lazy(() => import("../pages/Parents/Dashboard")));
const Page404 = Loadable(lazy(() => import("../pages/Auth/Page404")));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const PrivateRoutes = {
    path: "/dashboard",
    element: <AuthLayout />,
    children: [
        {
            path: "/dashboard",
            element: <Dashboard />,
        },
        { path: "*", element: <Page404 to="/404" /> },
    ],
};

export default PrivateRoutes;

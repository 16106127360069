import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./assets/css/style.css";
import "./assets/css/Media.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import CombineRoutes from "./routes/CombineRoutes";
import MessageProvider from "./components/MessageProvider";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";

const handelCloseNav = () => {
    const temp = document.getElementById("basic-navbar-nav");
    temp.classList.remove("show");
};

function App() {
    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <MessageProvider>
                        <div className="App" onClick={() => handelCloseNav()}>
                            <CombineRoutes />
                        </div>
                    </MessageProvider>
                </PersistGate>
            </Provider>
            <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}

export default App;

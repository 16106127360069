const apiPath = {
    spSignUp: process.env.REACT_APP_API_URL + "/v1/service-provider/signup",
    spForgotPassword:
        process.env.REACT_APP_API_URL +
        "/v1/service-provider/forgot-password-web",
    userForgotPassword:
        process.env.REACT_APP_API_URL + "/v1/user/forgot-password-web",
    resetPassword:
        process.env.REACT_APP_API_URL + "/v1/service-provider/reset-password",
    resendOtp:
        process.env.REACT_APP_API_URL + "/v1/service-provider/resend-otp",
    spLogin: process.env.REACT_APP_API_URL + "/v1/service-provider/login",
    userLogin: process.env.REACT_APP_API_URL + "/v1/user/login",
    userProfile: process.env.REACT_APP_API_URL + "/v1/user",
    userVerifyOtp: process.env.REACT_APP_API_URL + "/v1/user/verify-mobile",
    userReSendOtp: process.env.REACT_APP_API_URL + "/v1/user/resend-otp",
    userSignUp: process.env.REACT_APP_API_URL + "/v1/user/signup",
    spProfile: process.env.REACT_APP_API_URL + "/v1/service-provider/profile",
    staticPage: "/v1/user/static-content",
    spResetPassword:
        process.env.REACT_APP_API_URL +
        "/v1/service-provider/reset-password-web/",
    userResetPassword:
        process.env.REACT_APP_API_URL + "/v1/user/reset-password-web",

    // sp plans list
    getSubscriptionPlans:
        process.env.REACT_APP_API_URL +
        "/v1/service-provider/subscription-plans",
    buySubscription:
        process.env.REACT_APP_API_URL + "/v1/service-provider/buy-subscription",

    // user plans
    getUserSubscriptionPlans:
        process.env.REACT_APP_API_URL + "/v1/user/subscription-plans", //get plan list
    buyUserSubscriptionPlans:
        process.env.REACT_APP_API_URL + "/v1/user/buy-subscription", // buy plan
    //User
    getIndividualFaqs: "/v1/users/faqs",
    getHowsItwork: "/v1/users/hows",
    getSpotLightVideo: "/v1/users/spotLight-videos",
    getFeaturedVideo: "/v1/users/featured-videos",
    getSettings: "/v1/users/settings",
    Bussiness_form_data: "/v1/users/bussiness-data",
    newsletter_subscription: "/v1/users/add-newsletter",

    //Service Provider
    getSpFaqs: "/v1/service-providers/faqs",
    getSpHowItWork: "/v1/service-providers/hows",
    getSps: "/v1/service-providers/sps",
    getAds: "/v1/service-providers/ads",

    //stripe
    checkout: "/v1/checkout",

    //promocode
    couponcode: "/v1/users/add-promocode",
    couponcodeRemove: "/v1/users/remove-promocode",
};
export default apiPath;

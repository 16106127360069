import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import { api } from "../../services/api";
import apiPath from "../../../utilities/apiPath";
import { setChangeToOtp } from "../changeToOtp";

export const login = createAsyncThunk(
    "auth/login",
    async (
        {
            email,
            password,
            type,
            input,
        }: { email: string; password: string; type: string; input: string },
        { rejectWithValue, dispatch }
    ) => {
        const url =
            type.toLowerCase() === "user" ? apiPath.userLogin : apiPath.spLogin;
        return fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
                type: type,
                login_input: input,
            }),
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) {
                    let json = await response.json();
                    console.log(json.results);
                    if (json?.results?.token) {
                        return json.results;
                    } else if (json?.is_mobile_verified === 0) {
                        dispatch(
                            setChangeToOtp({
                                changeToOtp: true,
                                values: {
                                    mobile: json?.results?.mobile,
                                    email: email,
                                    country_code: json?.results?.country_code,
                                },
                            })
                        );
                        return rejectWithValue(false);
                    } else {
                        dispatch(
                            setMessage({ message: json.message, isError: true })
                        );
                        return rejectWithValue(false);
                    }
                } else {
                    let json = await response.json();
                    dispatch(
                        setMessage({ message: json.message, isError: true })
                    );
                    return rejectWithValue(false);
                }
            })
            .catch((error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                dispatch(setMessage({ message: message, isError: true }));
                return rejectWithValue(false);
            });
    }
);

export const userSignUp = createAsyncThunk(
    "auth/user-sign-up",
    async (values: any, { dispatch }) => {
        return fetch(apiPath.userSignUp, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) {
                    let json = await response.json();
                    if (json.success) {
                        dispatch(
                            setMessage({
                                message: json.message,
                                isError: false,
                            })
                        );
                        return true;
                    } else {
                        dispatch(
                            setMessage({ message: json.message, isError: true })
                        );
                        return false;
                    }
                } else {
                    let json = await response.json();
                    dispatch(
                        setMessage({ message: json.message, isError: true })
                    );
                    return false;
                }
            })
            .catch((error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                dispatch(setMessage({ message: message, isError: true }));
                return false;
            });
    }
);

export const verifyOtp = createAsyncThunk(
    "auth/verifyOtp",
    async (values, { rejectWithValue, dispatch }) => {
        return fetch(apiPath.userVerifyOtp, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) {
                    let json = await response.json();
                    if (json.success) {
                        dispatch(
                            setChangeToOtp({
                                changeToOtp: false,
                                values: null,
                            })
                        );
                        return json.results;
                    } else {
                        dispatch(
                            setMessage({ message: json.message, isError: true })
                        );
                    }
                } else {
                    let json = await response.json();
                    dispatch(
                        setMessage({ message: json.message, isError: true })
                    );
                    return rejectWithValue(false);
                }
            })
            .catch((error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                dispatch(setMessage({ message: message, isError: true }));
                return rejectWithValue(false);
            });
    }
);

export const resendOtp = createAsyncThunk(
    "auth/resendOtp",
    async (values, { dispatch }) => {
        return fetch(apiPath.userReSendOtp, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) {
                    let json = await response.json();
                    dispatch(
                        setMessage({ message: json.message, isError: false })
                    );
                    return true;
                } else {
                    let json = await response.json();
                    dispatch(
                        setMessage({ message: json.message, isError: true })
                    );
                    return false;
                }
            })
            .catch((error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                dispatch(setMessage({ message: message, isError: true }));
                return false;
            });
    }
);

export const logout = createAsyncThunk(
    "auth/logout",
    async (_, { dispatch }) => {
        dispatch({ type: "app/purgeStore" });
        dispatch(api.util.resetApiState());
    }
);

export const validateToken = createAsyncThunk(
    "auth/validateToken",
    async (token, { dispatch, rejectWithValue }) => {
        return fetch(apiPath.userProfile, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then(async (response) => {
                if (response.status === 200 || response.status === 201) {
                    let json = await response.json();
                    if (json.success) {
                        return { user: json.results, token: token };
                    }
                } else {
                    return rejectWithValue(false);
                }
            })
            .catch((error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                dispatch(setMessage({ message: message, isError: true }));
                return rejectWithValue(false);
            });
    }
);

const initialState: InitialState = {
    isLoggedIn: false,
    user: null,
    token: null,
    refresh_token: null,
};
const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // eslint-disable-next-line
        builder.addCase(login.fulfilled, (state, action) => {
            state.user = action.payload;
            state.isLoggedIn = true;
            state.token = action.payload.token;
            state.refresh_token = action.payload.refresh_token;
        }),
            builder.addCase(verifyOtp.fulfilled, (state, action) => {
                state.isLoggedIn = true;
                state.user = action.payload;
                state.token = action.payload.token;
                state.refresh_token = action.payload.refresh_token;
            }),
            builder.addCase(verifyOtp.rejected, (state) => {
                state.user = null;
                state.isLoggedIn = false;
                state.token = null;
                state.refresh_token = null;
            }),
            builder.addCase(logout.fulfilled, (state) => {
                state.user = null;
                state.isLoggedIn = false;
                state.token = null;
                state.refresh_token = null;
            }),
            builder.addCase(validateToken.fulfilled, (state, action) => {
                state.user = action.payload?.user;
                state.isLoggedIn = true;
                state.token = action.payload?.token ?? null;
            });
    },
});

export type InitialState = {
    isLoggedIn: boolean;
    user: any | null;
    token: string | null;
    refresh_token: string | null;
};
const { reducer } = authSlice;
export default reducer;

import { useRoutes } from "react-router-dom";
// routes
import MainRoutes from "./MainRoutes";
import PrivateRoutes from "./PrivateRoutes";
import { useAppSelector } from "../redux/store";

// ==============================|| ROUTING RENDER ||============================== //

export default function CombineRoutes() {
    const auth = useAppSelector((state) => state.auth);
    return useRoutes(
        [auth.isLoggedIn ? PrivateRoutes : [], MainRoutes],
        process.env.BASENAME
    );
}

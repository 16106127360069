import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TabState {
    selectedTab: string | null;
}

const initialState: TabState = {
    selectedTab: null,
};

const tabSlice = createSlice({
    name: 'tab',
    initialState,
    reducers: {
        setSelectedTab(state, action: PayloadAction<string>) {
            state.selectedTab = action.payload;
        },
        clearSelectedTab(state) {
            state.selectedTab = null;
        },
    },
});

export const { setSelectedTab, clearSelectedTab } = tabSlice.actions;
export default tabSlice.reducer;
import {
    Action,
    Reducer,
    combineReducers,
    configureStore,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import storage from "redux-persist/lib/storage";
import auth from "./slices/auth";
import message from "./slices/message";
import changeToOtp from "./slices/changeToOtp";
import tab from "./slices/subscriptionTab"
import { TypedUseSelectorHook, useDispatch } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import { useSelector } from "react-redux";
import { api } from "./services/api";

const reducers = combineReducers({
    auth: auth,
    message: message,
    api: api.reducer,
    changeToOtp: changeToOtp,
    tab: tab,
});

const persistConfig = {
    key: "root",
    storage: storage,
    whitelist: ["auth"],
};
const rootReducer: Reducer<any, Action> = (state, action) => {
    if (action.type === "app/purgeStore") {
        // Clean-up redux-persist's storage engine
        storage.removeItem("persist:root");

        state = {} as RootState;
    }
    return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(api.middleware),
});
const persistor = persistStore(store);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { store, persistor };

import { lazy } from "react";

// project imports
import Loadable from "../components/Loader/Loadable";
import BaseLayout from "../layouts/BaseLayout/BaseLayout";

const Index = Loadable(lazy(() => import("../pages/Index")));
const Faq = Loadable(lazy(() => import("../pages/Cms/Faq")));
const Blog = Loadable(lazy(() => import("../pages/Cms/Blog")));
const Login = Loadable(lazy(() => import("../pages/Auth/Login")));
const ForgotPassword = Loadable(
    lazy(() => import("../pages/Auth/passwordRecovery"))
);
const RecoveryPassword = Loadable(
    lazy(() => import("../pages/Auth/resetPassword"))
);
const VerifyOtp = Loadable(lazy(() => import("../pages/Auth/VerifyOTP")));
const Signup = Loadable(lazy(() => import("../pages/Auth/Signup")));
const Business = Loadable(lazy(() => import("../pages/Cms/Business")));
const Caregiver = Loadable(lazy(() => import("../pages/Cms/Caregiver")));
const Parents = Loadable(lazy(() => import("../pages/Cms/Parents")));
const Subscription = Loadable(lazy(() => import("../pages/Cms/Subscription")));
const CareDetailForm = Loadable(
    lazy(() => import("../pages/Cms/CareDetailForm"))
);
const TermAndCondition = Loadable(
    lazy(() => import("../pages/Cms/TermAndCondition"))
);
const ParentContract = Loadable(
    lazy(() => import("../pages/Cms/ParentContract"))
);
const RbtContract = Loadable(lazy(() => import("../pages/Auth/RbtContract")));
const PrivacyPolicy = Loadable(
    lazy(() => import("../pages/Cms/PrivacyPolicy"))
);
const CheckoutSuccess = Loadable(
    lazy(() => import("../pages/Cms/CheckoutSuccess"))
);
const CheckoutFail = Loadable(lazy(() => import("../pages/Cms/CheckoutFail")));
const CheckoutSuccessCustomer = Loadable(
    lazy(() => import("../pages/Cms/CheckoutSuccessCustomer"))
);
const UploadDoc = Loadable(lazy(() => import("../pages/Cms/UploadDocument")));
const ParentSignUp = Loadable(lazy(() => import("../pages/Auth/ParentSignUp")));
const CareGiverSignUp = Loadable(
    lazy(() => import("../pages/Auth/CaregiverSignUp"))
);

// All common routes
const MainRoutes = {
    path: "/",
    element: <BaseLayout />,
    children: [
        {
            path: "/",
            element: <Index />,
        },
        {
            path: "/login",
            element: <Login />,
        },
        {
            path: "/forgot-password",
            element: <ForgotPassword />,
        },
        {
            path: "/reset-password",
            element: <RecoveryPassword />,
        },
        {
            path: "/",
            element: <VerifyOtp />,
        },
        {
            path: "/signup",
            element: <Signup />,
        },
        {
            path: "/signup/parents",
            element: <ParentSignUp />,
        },
        {
            path: "/signup/caregiver",
            element: <CareGiverSignUp />,
        },
        {
            path: "/faq",
            element: <Faq />,
        },
        {
            path: "/blogs",
            element: <Blog />,
        },
        {
            path: "/subscription",
            element: <Subscription />,
        },
        {
            path: "/onboarding-occasionally",
            element: <CareDetailForm />,
        },
        {
            path: "/caregiver",
            element: <Caregiver />,
        },
        {
            path: "/business",
            element: <Business />,
        },
        {
            path: "/term-and-condition",
            element: <TermAndCondition />,
        },
        {
            path: "/guardian-contract",
            element: <ParentContract />,
        },
        {
            path: "/rbt-contract",
            element: <RbtContract />,
        },
        {
            path: "/privacy-policy",
            element: <PrivacyPolicy />,
        },
        {
            path: "/parents",
            element: <Parents />,
        },
        {
            path: "/checkout-success/:id",
            element: <CheckoutSuccess />,
        },
        {
            path: "/checkout-success-customer/:id",
            element: <CheckoutSuccessCustomer />,
        },
        {
            path: "/checkout-fail",
            element: <CheckoutFail />,
        },
        {
            path: "/upload-document",
            element: <UploadDoc />,
        },
    ],
};

export default MainRoutes;

import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
    message: string;
    isError: boolean | null;
};

const initialState: InitialState = { message: "", isError: null };

const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
        setMessage: (_, action) => {
            return {
                message: action.payload.message,
                isError: action.payload.isError,
            };
        },
        clearMessage: () => {
            return { message: "", isError: null };
        },
    },
});

const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage } = actions;
export default reducer;

import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
    changeToOtp: boolean;
    values: any | null;
};

const initialState: InitialState = { changeToOtp: false, values: null };

const changeToOtpSlice = createSlice({
    name: "changeToOtp",
    initialState,
    reducers: {
        setChangeToOtp: (_, action) => {
            return {
              changeToOtp: action.payload.changeToOtp,
              values: action.payload.values,
            };
        },
    },
});

const { reducer, actions } = changeToOtpSlice;

export const { setChangeToOtp } = actions;
export default reducer;



import React from "react";
import { Navbar, Container, Nav, Button } from "react-bootstrap";

import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../redux/store";
import { useDispatch } from "react-redux";
import { logout } from "../redux/slices/auth";
const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAppSelector((state) => state.auth);
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout()).then(() => {
            navigate("/login", { replace: true });
        });
    };

    const handleSelect = (eventKey) => `activeKey = ${eventKey}`;
    return (
        <header>
            <Navbar expand="md" className="border-bottom">
                <Container>
                    <Navbar.Brand
                        onClick={() => navigate("/", { replace: true })}
                    >
                        <img src="../images/logo.svg" alt="" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav
                            defaultActiveKey="1"
                            onSelect={handleSelect}
                            className="ms-auto"
                        >
                            <Nav.Link
                                eventKey="1"
                                onClick={() => navigate("/", { replace: true })}
                            >
                                Home
                            </Nav.Link>
                            <Nav.Link
                                eventKey="2"
                                onClick={() =>
                                    navigate("/business", { replace: true })
                                }
                            >
                                About Us
                            </Nav.Link>
                            <Nav.Link
                                eventKey="3"
                                onClick={() =>
                                    navigate("/subscription", {
                                        replace: true,
                                        state: { new: true },
                                    })
                                }
                            >
                                Subscription
                            </Nav.Link>
                            <Nav.Link
                                eventKey="4"
                                onClick={() =>
                                    navigate("/blogs", { replace: true })
                                }
                            >
                                Blog
                            </Nav.Link>
                            <Nav.Link
                                eventKey="5"
                                onClick={() =>
                                    navigate("/faq", { replace: true })
                                }
                            >
                                FAQ
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    {auth.isLoggedIn ? (
                        <div className="d-flex align-items-center">
                            <p className="mx-2 mt-3">{auth.user.full_name}</p>
                            <Button
                                onClick={handleLogout}
                                style={{
                                    backgroundColor: "#00aeba",
                                    borderColor: "#00aeba",
                                }}
                                className="custome_btn text-white"
                            >
                                Logout
                            </Button>
                        </div>
                    ) : (
                        <>
                            {location.pathname.includes("signup") && (
                                <Button
                                    onClick={() =>
                                        navigate("/login", { replace: true })
                                    }
                                    style={{
                                        backgroundColor: "#00aeba",
                                        borderColor: "#00aeba",
                                        marginLeft: "5px",
                                    }}
                                    className="custome_btn text-white"
                                >
                                    Login
                                </Button>
                            )}
                            {location.pathname === "/login" && (
                                <Button
                                    onClick={() =>
                                        navigate("/signup", { replace: true })
                                    }
                                    style={{
                                        backgroundColor: "#00aeba",
                                        borderColor: "#00aeba",
                                    }}
                                    className="custome_btn text-white"
                                >
                                    Sign Up
                                </Button>
                            )}
                            {location.pathname !== "/login" &&
                                !location.pathname.includes("signup") && (
                                    <>
                                        <Button
                                            onClick={() =>
                                                navigate("/login", {
                                                    replace: true,
                                                })
                                            }
                                            style={{
                                                backgroundColor: "#00aeba",
                                                borderColor: "#00aeba",
                                                marginLeft: "5px",
                                            }}
                                            className="custome_btn text-white"
                                        >
                                            Login
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                navigate("/signup", {
                                                    replace: true,
                                                })
                                            }
                                            style={{
                                                backgroundColor: "#00aeba",
                                                borderColor: "#00aeba",
                                                marginLeft: "5px",
                                            }}
                                            className="custome_btn text-white"
                                        >
                                            Sign Up
                                        </Button>
                                    </>
                                )}
                        </>
                    )}
                    {/* <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="d-flex align-items-center">
            <img src="../images/user_icon.svg" className="mx-2 "/>
              User
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
                </Container>
            </Navbar>
        </header>
    );
};

export default Header;

import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
// import { useGetUserProfileQuery } from "../../redux/services/modules/user";

const AuthLayout = () => {
    // const { data } = useGetUserProfileQuery()
    // useEffect(() => {
    //     if (!Session.getSession("Auth")) {
    //         navigate("/", { replace: true });
    //     } else {
    //         // getUserProfile();
    //     }
    // }, []);

    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    );
};

export default AuthLayout;

import * as CryptoJS from 'crypto-js';
import SecureStorage from 'secure-web-storage';

const SECRET_KEY = process.env.REACT_APP_WEB_STORAGE_SECURITY;

const secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key) {
        key = CryptoJS.SHA256(key, SECRET_KEY);

        return key.toString();
    },
    encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);

        data = data.toString();

        return data;
    },
    decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, SECRET_KEY);

        data = data.toString(CryptoJS.enc.Utf8);

        return data;
    }
});

const Session = (function () {
    const getSession = function (key) {
        return secureStorage.getItem(key);
    };

    const setSession = function (key, data) {
        secureStorage.setItem(key, data);
    };

    const clearAllSession = function () {
        return secureStorage.clear();
    };

    const clearSession = function (key) {
        return secureStorage.removeItem(key);
    };

    return {
        getSession,
        setSession,
        clearAllSession,
        clearSession
    };
})();

export default Session;

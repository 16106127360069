"use client";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { clearMessage } from "../redux/slices/message";
import { RootState } from "../redux/store";
import { Box } from "@mui/material";

type MessageState = RootState["message"];

function MessageProvider({ children }: { children: React.ReactNode }) {
    const message = useSelector<RootState, MessageState>(
        (state) => state.message
    );
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    useEffect(() => {
        if (message?.message) {
            if (message?.isError === true) {
                toast.error(message?.message);
                setTimeout(() => {
                    dispatch(clearMessage());
                }, 2000);
            } else if (message?.isError === false) {
                toast(message?.message);
                setTimeout(() => {
                    dispatch(clearMessage());
                }, 2000);
            }
        }
    }, [message, dispatch]);

    return <Box>{children}</Box>;
}

export default MessageProvider;

import axios from "axios";
import Session from "./session";
const BASE_URL = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: BASE_URL,
});
let options={ "Content-Type": "application/json"};
const CheckAuthData = Session.getSession("Auth");
if(CheckAuthData)
{
  const token = CheckAuthData?.refresh_token || "";
  options = {
      "Content-Type": "application/json",
      "Authorization": 'Bearer '+token,
    };

}

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: options,
  withCredentials: false,
});